import { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Form,
  Segment,
  Label,
  Progress,
  Icon,
  Dropdown,
  Popup,
} from 'semantic-ui-react'
import lodash from 'lodash'

const {
  isNumber,
} = lodash

const LOCAL_STORAGE_KEYS = {
  QUESTIONS: 'fw:customQuestions',
  ANSWERS: 'fw:customAnswers',
}

const MSG_COLORS_BY_SEVERITY = {
  '0': 'Green',
  '1': 'Yellow',
  '2': 'Orange',
  '3': 'Red',
}

const DEFAULT_QUESTIONS = [
  'If you have felt sadness over the last year and a half, what has made you feel this way?',
  'In the space below, please write down 2 of your risk factors:',
  'Do you ever feel like you are a burden to others?',
  'Please describe the closest friendships in your life.',
  'Do you feel like people understand you? Yes or no, please explain.',
  'What is your definition of "bullying"?',
].map((text) => ({
  text,
  value: text,
  icon: 'certificate',
}))
const SAVED_CUSTOM_QUESTIONS = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.QUESTIONS) || '[]')
const DEFAULT_ANSWERS = [
  'My stepdad passing away',
  'I am two different minorities, drug users in my family, suicide in my family, father is depressed, mother had breast cancer.',
  'Yes even in current day I feel like a burden to my sister',
  'I don\'t have any friends. I hate my life and want it to end',
  'I don\'t have friends really',
  'Nobody understands me.',
  'Someone talking bad about you and physically and/or mentally hurt you.',
].map((text) => ({
  text,
  value: text,
  icon: 'certificate',
}))
const SAVED_CUSTOM_ANSWERS = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.ANSWERS) || '[]')

const FirewordForm = ({
  isLoading,
  severity,
  confidence,
  onSubmit,
}) => {
  const [ question, setQuestion ] = useState('')
  const [ answer, setAnswer ] = useState('')
  const [ questionOptions, setQuestionOptions ] = useState(SAVED_CUSTOM_QUESTIONS.concat(DEFAULT_QUESTIONS))
  const [ answerOptions, setAnswerOptions ] = useState(SAVED_CUSTOM_ANSWERS.concat(DEFAULT_ANSWERS))
  return (
    <Segment>
      <Form onSubmit={() => onSubmit({
        question,
        answer,
      })}>
          <div style={{
            display: 'flex',
            alignItems: 'flex-end',
            marginBottom: 10,
          }}>
            <div style={{
              flexGrow: 1,
              marginRight: 10,
            }}>
              <label style={{
                display: 'block',
                fontWeight: 'bold',
                marginBottom: 4,
              }}>Question</label>
              <Dropdown
                options={questionOptions}
                placeholder='Choose a question or write your own'
                search
                fluid
                selection
                allowAdditions
                additionLabel='Custom Question: '
                value={question}
                disabled={isLoading}
                onAddItem={(_, { value }) => {
                  const allOptions = [
                    {
                      text: value,
                      value,
                      icon: 'user',
                    },
                    ...questionOptions,
                  ]
                  setQuestionOptions(allOptions)
                  const customOptions = allOptions.filter((opt) => opt.icon === 'user')
                  localStorage.setItem(LOCAL_STORAGE_KEYS.QUESTIONS, JSON.stringify(customOptions))
                }}
                onChange={(_, data) => setQuestion(data.value)}
              />
            </div>
            <Popup
              content='Clear out custom questions from list'
              position='top right'
              flowing
              mouseEnterDelay={300}
              trigger={
                <Button
                  style={{ marginBottom: 1 }}
                  floated='right'
                  type='button'
                  icon
                  onClick={() => {
                    setQuestionOptions(questionOptions.filter((opt) => opt.icon !== 'user'))
                    localStorage.removeItem(LOCAL_STORAGE_KEYS.QUESTIONS)
                  }}
                >
                  <Icon name='remove user' />
                </Button>
              }
            />
          </div>
          <div style={{
            display: 'flex',
            alignItems: 'flex-end',
            marginBottom: 10,
          }}>
            <div style={{
              flexGrow: 1,
              marginRight: 10,
            }}>
              <label style={{
                display: 'block',
                fontWeight: 'bold',
                marginBottom: 4,
              }}>Answer</label>
              <Dropdown
                options={answerOptions}
                placeholder='Choose an answer or write your own'
                search
                selection
                fluid
                allowAdditions
                additionLabel='Custom Answer: '
                value={answer}
                disabled={isLoading}
                onAddItem={(_, { value }) => {
                  const allOptions = [
                    {
                      text: value,
                      value,
                      icon: 'user',
                    },
                    ...answerOptions,
                  ]
                  setAnswerOptions(allOptions)
                  const customOptions = allOptions.filter((opt) => opt.icon === 'user')
                  localStorage.setItem(LOCAL_STORAGE_KEYS.ANSWERS, JSON.stringify(customOptions))
                }}
                onChange={(_, data) => setAnswer(data.value)}
              />
            </div>
            <Popup
              content='Clear out custom answers from list'
              position='top right'
              flowing
              mouseEnterDelay={300}
              trigger={
                <Button
                  style={{ marginBottom: 1 }}
                  floated='right'
                  type='button'
                  icon
                  onClick={() => {
                    setAnswerOptions(answerOptions.filter((opt) => opt.icon !== 'user'))
                    localStorage.removeItem(LOCAL_STORAGE_KEYS.ANSWERS)
                  }}
                >
                  <Icon name='remove user' />
                </Button>
              }
            />
          </div>
        <Button
          data-public
          type='submit'
          loading={isLoading}
          disabled={isLoading}
          color='blue'
          floated='right'
        >
          Test
        </Button>
        <br style={{ clear: 'both' }}/>
        {(isNumber(severity) && isNumber(confidence)) && (
          <div style={{
            marginTop: 20,
          }}>
            <Label
              size='huge'
              color={MSG_COLORS_BY_SEVERITY[severity]?.toLowerCase()}
              style={{
                width: '100%',
                textAlign: 'center',
                marginBottom: 20,
              }}
            >
              <Icon name={(severity === 0) ? 'shield' : 'fire'} />
              {(severity === 0) ? 'Safe' : MSG_COLORS_BY_SEVERITY[severity]}
            </Label>
            <Progress
              percent={Math.floor(confidence * 100)}
              progress
              label='Confidence'
              size='small'
            />
          </div>
        )}
      </Form>
    </Segment>
  )
}

FirewordForm.propTypes = {
  isLoading: PropTypes.bool,
  onSubmit: PropTypes.func,
  severity: PropTypes.number,
  confidence: PropTypes.number,
}

FirewordForm.defaultProps = {
}

export default FirewordForm
