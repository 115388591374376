import {
  useState,
  useEffect,
  useContext,
  createContext,
} from 'react'
import lodash from 'lodash'

const { throttle } = lodash
const viewportContext = createContext({})

// eslint-disable-next-line react/prop-types
export const ViewportProvider = ({ children }) => {
  // This is the exact same logic that we previously had in our hook

  const [ width, setWidth ] = useState(window.innerWidth)
  const [ height, setHeight ] = useState(window.innerHeight)

  const handleWindowResize = () => {
    setWidth(window.innerWidth)
    setHeight(window.innerHeight)
  }

  useEffect(() => {
    window.addEventListener('resize', throttle(handleWindowResize, 500))
    return () => window.removeEventListener('resize', handleWindowResize)
  }, [])

  return (
    <viewportContext.Provider value={{
      width,
      height,
    }}>
      {children}
    </viewportContext.Provider>
  )
}

export const useViewport = () => {
  const { width, height } = useContext(viewportContext)
  return {
    width,
    height,
  }
}
