import React from 'react'
import ReactDOM from 'react-dom/client'
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'

import App from './App'
import { ViewportProvider } from './utils/viewport'
import reportWebVitals from './reportWebVitals'

import './index.css'
import 'semantic-ui-css/semantic.min.css'

const queryClient = new QueryClient()
const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <React.StrictMode>
    <ViewportProvider>
      <QueryClientProvider client={queryClient}>
        <App/>
      </QueryClientProvider>
    </ViewportProvider>
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
