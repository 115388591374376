import { useState } from 'react'
import {
  Container,
  Message,
  Input,
  Icon,
  Tab,
  TabPane,
  AccordionTitle,
  AccordionContent,
  Accordion,
} from 'semantic-ui-react'
import { useMutation } from '@tanstack/react-query'

import FirewordForm from './components/fireword-form/fireword-form'
import { useViewport } from './utils/viewport'

import './App.css'

const panes = [
  {
    menuItem: {
      key: 'red',
      icon: 'fire',
      color: 'red',
      content: 'Red',
    },
    render: () => (
      <TabPane>
        <p>{'Red firewords are those that show potential for SERIOUS harm to one\'s self or others and would require immediate attention.'}</p>
        <p>{'This includes, but is not limited to: major drug use, severe hopelessness/suffering, violence, suicidal speech, and abuse (sexual or otherwise).'}</p>
        <p>{'Some examples of these are:'}</p>
        <ul>
          <li>{'I hate myself and wish I was not even born'}</li>
          <li>{'I really dont know whats gonna happen but i might kms'}</li>
          <li>{'Someone threatened to shoot me'}</li>
        </ul>
      </TabPane>
    ),
  },
  {
    menuItem: {
      key: 'orange',
      icon: 'fire',
      color: 'orange',
      content: 'Orange',
    },
    render: () => (
      <TabPane>
        <p>{'Orange firewords are those that show potential for harm to one\'s self or others and would require someone\'s attention in the next 24 hours.'}</p>
        <p>{'This includes, but is not limited to: vague mentions of weapons, serious anger management issues, moderate negative self-talk, mild self-harm, current mild drug use, and harmful family life.'}</p>
        <p>{'Some examples of these are:'}</p>
        <ul>
          <li>{'My parents hate me'}</li>
          <li>{'Guns are cool'}</li>
          <li>{'I do drugs every once in a while'}</li>
        </ul>
      </TabPane>
    ),
  },
  {
    menuItem: {
      key: 'yellow',
      icon: 'fire',
      color: 'yellow',
      content: 'Yellow',
    },
    render: () => (
      <TabPane>
        <p>{'Yellow firewords are those that show potential for mild harm to one\'s self or others, or that one may be at risk for more concerning behavior in the future.'}</p>
        <p>{'This includes, but is not limited to: behavior issues, recent bullying, concern for someone elses safety, talk about depression/sadness, mild violence (hitting/shoving), fear of death/violence, mild drug use, and trust issues.'}</p>
        <p>{'Some examples of these are:'}</p>
        <ul>
          <li>{'I do not trust my friends'}</li>
          <li>{'I got in a fight and punched a kid'}</li>
          <li>{'Sometimes I vape at home'}</li>
        </ul>
      </TabPane>
    ),
  },
  {
    menuItem: {
      key: 'green',
      icon: 'shield',
      color: 'green',
      content: 'Safe',
    },
    render: () => (
      <TabPane>
        <p>{'Safe language is anything that is not considered a Fireword. This does NOT mean that there is nothing of concern. There could still be something to look into or other questions/answers that need review.'}</p>
        <p>{'Subjects that are considered "safe" but may require more investigation are: asking for help with academic or general life situations, weight related insecurity, vague dislike of school, or mild violence toward objects.'}</p>
        <p>{'Some examples of these are:'}</p>
        <ul>
          <li>{'School is really hard this year'}</li>
          <li>{'I need to lose weight'}</li>
          <li>{'When I get mad, I punch the wall'}</li>
        </ul>
      </TabPane>
    ),
  },
]

function App () {
  const [ password, setPassword ] = useState('')
  const [ showPassword, setShowPassword ] = useState(false)
  const [ activeIndex, setActiveIndex ] = useState(-1)
  const { width } = useViewport()
  const {
    isPending,
    isError,
    data,
    error,
    mutate,
  } = useMutation({
    mutationFn: async (data) => {
      if (!password) {
        throw new Error('Please type in the password in order to use this app')
      }
      if (!data.question) {
        throw new Error('Please type in a question to test')
      }
      if (!data.answer) {
        throw new Error('Please type in an answer to test')
      }
      const response = await fetch('https://firewords-prd.base.education/prd/api', {
        method: 'POST',
        headers: {
          authorization: password,
        },
        body: JSON.stringify({
          question: data.question,
          answer: data.answer,
        }),
      })
      if (!response.ok) {
        if (response.status === 401) {
          throw new Error('The password you entered is invalid')
        }
        throw new Error('Server Error. Please try your request again.')
      }
      return response.json()
    },
    enabled: false,
  })

  return (
    <Container style={{ paddingTop: 15 }}>
      <h1>-{process.env.REACT_APP_TEST_VAR}- This is SM-1000!</h1>
      <Message
        info
        header='Welcome to the Firewords Test Application'
        content={(
          <div style={{
            marginTop: 5,
            display: 'flex',
            alignItems: 'flex-start',
            flexDirection: (width < 600) ? 'column' : 'row',
          }}>
            <p style={{ marginRight: 10 }}>{'This is a simple application that can be used to test out the Fireword system. In order to use this, you need a password. If you don\'t have one, please reach out to mac.angell@7mindsets.com. Once you have a valid password, enter it into the password field and then type in a question and an answer and hit the "Test" button. Once submitted, you will see what severity your answer is classified as and how confident the prediction was.'}</p>
            <Input
              label='Password'
              icon={<Icon name={(showPassword) ? 'eye slash' : 'eye'} link onClick={() => setShowPassword(!showPassword)}/>}
              type={(showPassword) ? 'text' : 'password'}
              disabled={isPending}
              onChange={(_, data) => setPassword(data.value)}
            />
          </div>
        )}
      />
      <Accordion>
        <AccordionTitle
          active={activeIndex === 0}
          index={0}
          onClick={() => (activeIndex === 0) ? setActiveIndex(-1) : setActiveIndex(0)}
        >
          <Icon name='dropdown' />
          Fireword Guidelines
        </AccordionTitle>
        <AccordionContent active={activeIndex === 0}>
          <Tab
            panes={panes}
            menu={{
              pointing: true,
              attached: 'top',
            }}
          />
        </AccordionContent>
      </Accordion>
      {(isError) && (
        <Message negative>
          <Message.Header>There was a problem</Message.Header>
          <p>{error.message}</p>
        </Message>
      )}
      <FirewordForm
        isLoading={isPending}
        onSubmit={(d) => mutate(d)}
        {...data}
      />
    </Container>
  )
}

export default App
